<template>
  <div class="container">
    <div class="tag-manage-warp">
      <div class="tag-manage-title">标签管理</div>
      <!-- hco -->
      <div class="tag-manage-hco">
        <div class="tag-manage-hco-top">
          <span class="hco-top-name">HCO</span>
          <span class="hco-top-num">{{ hcoTagsNum }}</span>
          <span class="hco-top-create" @click="hanldeOpenTagRule('HCO')">
            <i class="el-icon-plus"></i>
            新建标签
          </span>
        </div>
        <div class="tag-manage-hco-content">
          <div class="hco-basic-attribute content-attributes">
            <div class="attribute-title">基本属性</div>
            <div class="attribute-item-warp">
              <div v-for="(item, index) in hcoTagValue.basic" :key="index">
                <el-tooltip effect="dark" :content="item.description" placement="top">
                  <span :class="{ 'no-shelf': item.on_self !== 2 }" @click="gotoTagDetail(item)">
                    {{ item.name }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="hco-position-attribute content-attributes">
            <div class="attribute-title">位置属性</div>
            <div class="attribute-item-warp">
              <div v-for="(item, index) in hcoTagValue.position" :key="index">
                <el-tooltip effect="dark" :content="item.description" placement="top">
                  <span :class="{ 'no-shelf': item.on_self !== 2 }" @click="gotoTagDetail(item)">
                    {{ item.name }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="hco-business-attribute content-attributes">
            <div class="attribute-title">经营属性</div>
            <div class="attribute-item-warp">
              <div v-for="(item, index) in hcoTagValue.business" :key="index">
                <el-tooltip effect="dark" :content="item.description" placement="top">
                  <span :class="{ 'no-shelf': item.on_self !== 2 }" @click="gotoTagDetail(item)">
                    {{ item.name }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="hco-diagnosis-attribute content-attributes">
            <div class="attribute-title">诊断属性</div>
            <div class="attribute-item-warp">
              <div v-for="(item, index) in hcoTagValue.diagnosis" :key="index">
                <el-tooltip effect="dark" :content="item.description" placement="top">
                  <span :class="{ 'no-shelf': item.on_self !== 2 }" @click="gotoTagDetail(item)">
                    {{ item.name }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="hco-tencent content-attributes">
            <div class="attribute-title">腾讯特色</div>
            <div class="attribute-item-warp">
              <div v-for="(item, index) in hcoTagValue.special" :key="index">
                <el-tooltip effect="dark" :content="item.description" placement="top">
                  <span :class="{ 'no-shelf': item.on_self !== 2 }" @click="gotoTagDetail(item)">
                    {{ item.name }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- hcp -->
      <div class="tag-manage-hcp">
        <div class="tag-manage-hcp-top">
          <span class="hcp-top-name">HCP</span>
          <span class="hcp-top-num">{{ hcpTagsNum }}</span>
          <span class="hcp-top-create" @click="hanldeOpenTagRule('HCP')">
            <i class="el-icon-plus"></i>
            新建标签
          </span>
        </div>
        <div class="tag-manage-hcp-content">
          <div class="hcp-basic-attribute content-attributes">
            <div class="attribute-title">基本属性</div>
            <div class="attribute-item-warp">
              <div v-for="(item, index) in hcpTagValue.basic" :key="index">
                <el-tooltip effect="dark" :content="item.description" placement="top">
                  <span :class="{ 'no-shelf': item.on_self !== 2 }" @click="gotoTagDetail(item)">
                    {{ item.name }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="hcp-work-attribute content-attributes">
            <div class="attribute-title">工作属性</div>
            <div class="attribute-item-warp">
              <div v-for="(item, index) in hcpTagValue.work" :key="index">
                <el-tooltip effect="dark" :content="item.description" placement="top">
                  <span :class="{ 'no-shelf': item.on_self !== 2 }" @click="gotoTagDetail(item)">
                    {{ item.name }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="hcp-practice-attribute content-attributes">
            <div class="attribute-title">执业属性</div>
            <div class="attribute-item-warp">
              <div v-for="(item, index) in hcpTagValue.practice" :key="index">
                <el-tooltip effect="dark" :content="item.description" placement="top">
                  <span :class="{ 'no-shelf': item.on_self !== 2 }" @click="gotoTagDetail(item)">
                    {{ item.name }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="hcp-academic-attribute content-attributes">
            <div class="attribute-title">学术属性</div>
            <div class="attribute-item-warp">
              <div v-for="(item, index) in hcpTagValue.academic" :key="index">
                <el-tooltip effect="dark" :content="item.description" placement="top">
                  <span :class="{ 'no-shelf': item.on_self !== 2 }" @click="gotoTagDetail(item)">
                    {{ item.name }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="hcp-tencent content-attributes">
            <div class="attribute-title">腾讯特色</div>
            <div class="attribute-item-warp">
              <div v-for="(item, index) in hcpTagValue.special" :key="index">
                <el-tooltip effect="dark" :content="item.description" placement="top">
                  <span :class="{ 'no-shelf': item.on_self !== 2 }" @click="gotoTagDetail(item)">
                    {{ item.name }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新建HCO规则标签 -->
    <el-drawer
      :title="createRuleTagTitle"
      :visible.sync="showCreateRuleTag"
      direction="rtl"
      :size="480"
      class="createRuleTag-drawer-warp"
      :before-close="closeCreateRuleTag"
    >
      <div class="createRuleTag-drawer-content">
        <el-form :model="ruleForm" :rules="rules" ref="createRuleTagForm">
          <el-form-item label="标签名称" prop="tag_name" class="tagNameItem">
            <el-input
              v-model="ruleForm.tag_name"
              placeholder="请输入"
              maxlength="10"
              @input="handlecheckTagName"
            />
            <div class="tag_name_des">一级分类下标签名称不能重复,长度限10个字</div>
          </el-form-item>

          <el-form-item label="标签说明" prop="tag_description">
            <el-input
              type="textarea"
              maxlength="50"
              v-model="ruleForm.tag_description"
              placeholder="请填写(选填)"
              show-word-limit
            />
          </el-form-item>

          <el-form-item label="分类" prop="tag_classification">
            <el-select v-model="ruleForm.tag_classification" placeholder="请选择">
              <el-option
                v-for="item in tagCategory"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="属性" prop="tag_attribute">
            <el-select v-model="ruleForm.tag_attribute" placeholder="请选择">
              <el-option
                v-for="item in tagAttributes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="值类型"
            prop="tag_value_type"
            :class="{ valTypeItem: ruleForm.tag_value_type === 'text' }"
          >
            <el-select
              v-model="ruleForm.tag_value_type"
              placeholder="请选择"
              @change="changeValType"
            >
              <el-option
                v-for="item in tagValueTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <div class="tag_value_type_des" v-show="ruleForm.tag_value_type === 'text'">
              计算时系统会按值顺序进行数据匹配,优先匹配顺序靠前的值
            </div>
          </el-form-item>

          <el-form-item label="标签值" prop="tag_value">
            <el-date-picker
              v-if="ruleForm.tag_value_type === 'date'"
              v-model="ruleForm.tag_value"
              placeholder="请选择一个日期"
              class="tagVal_datapicker"
            ></el-date-picker>

            <el-input
              v-else
              type="textarea"
              :autosize="{ maxRows: 10 }"
              v-model="ruleForm.tag_value"
              placeholder="以换行区分多个值,值不能重复,最多不能超过500个"
              @input="handlecheckTagValue"
            />
          </el-form-item>

          <el-form-item label="更新方式">
            <el-radio-group v-model="ruleForm.runType">
              <el-radio :label="1">手动更新</el-radio>
              <el-radio :label="2">
                定时更新
                <span style="color: #98a5b2">(注：每月1号更新)</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>

        <div class="createRuleTag-drawer-footer">
          <el-button
            slot="reference"
            v-auto-unfocus
            class="createRuleTag-drawer-button submitBtn"
            type="primary"
            @click="submitForm"
          >
            保存
          </el-button>

          <el-button class="createRuleTag-drawer-button cancelBtn" @click="closeCreateRuleTag">
            取消
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { SPECIAL_CHAR } from '@/utils/constant';
import { GetTags, CreateTag } from '@/api/tag.js';
import { getDate } from '@/utils/util';

export default {
  data() {
    return {
      hcoTag: [
        { label: '基本属性', value: 'basic' },
        { label: '位置属性', value: 'position' },
        { label: '经营属性', value: 'business' },
        { label: '诊断属性', value: 'diagnosis' },
        { label: '腾讯特色', value: 'special' },
      ],
      hcpTag: [
        { label: '基本属性', value: 'basic' },
        { label: '工作属性', value: 'work' },
        { label: '执业属性', value: 'practice' },
        { label: '学术属性', value: 'academic' },
        { label: '腾讯特色', value: 'special' },
      ],
      // hco标签值
      hcoTagValue: {
        basic: [],
        position: [],
        business: [],
        diagnosis: [],
        special: [],
      },
      // hcp标签值
      hcpTagValue: {
        basic: [],
        work: [],
        practice: [],
        academic: [],
        special: [],
      },
      // 新建HCO/HCP规则标签抽屉
      showCreateRuleTag: false,
      // 新建HCO/HCP规则标签标题
      createRuleTagTitle: '新建HCO规则标签',
      ruleForm: {
        tag_name: '',
        tag_description: '',
        tag_classification: '',
        tag_attribute: 'basic',
        tag_value_type: 'text',
        tag_value: '',
        runType: 1,
      },
      rules: {
        tag_name: [{ required: true, message: '请填写标签名称', trigger: 'blur' }],
        tag_classification: [{ required: true, message: '请选择分类', trigger: 'change' }],
        tag_attribute: [{ required: true, message: '请选择属性', trigger: 'change' }],
        tag_value_type: [{ required: true, message: '请选择值类型', trigger: 'change' }],
        tag_value: [{ required: true, message: '请填写标签值', trigger: 'blur' }],
      },

      // 标签属性
      tagAttributes: [
        { label: '基础', value: 'basic' },
        { label: '衍生', value: 'derive' },
      ],
      // 标签值类型
      tagValueTypes: [
        { label: '文本', value: 'text' },
        { label: '多值文本', value: 'multi-text' },
        { label: '整数', value: 'integer-num' },
        { label: '小数', value: 'decimal-num' },
        { label: '日期', value: 'date' },
      ],
    };
  },
  computed: {
    // 角色权限判定
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
    // 标签分类
    tagCategory() {
      if (this.createRuleTagTitle === '新建HCP规则标签') {
        return this.hcpTag;
      }
      return this.hcoTag;
    },

    // 计算hco标签个数
    hcoTagsNum() {
      let num = 0;
      Object.keys(this.hcoTagValue).forEach(ele => {
        num += this.hcoTagValue[ele].length;
      });
      return num;
    },
    // 计算hcp标签个数
    hcpTagsNum() {
      let num = 0;
      Object.keys(this.hcpTagValue).forEach(ele => {
        num += this.hcpTagValue[ele].length;
      });
      return num;
    },
    systemEnv() {
      // systemEnv为1表示管理端，2表示客户端
      return localStorage.getItem('systemEnv');
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'tag-management') {
        this.init();
      }
    },
    role(newval) {
      if (newval === 3) {
        this.$message.error('无权限进入');
        this.$router.push('/dashboard/home');
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getAllTags();
    },

    // 获取所有标签
    getAllTags() {
      const req = {
        index: 1,
        page_size: 99999,
      };
      GetTags(req).then(res => {
        console.log('获取到的全部标签', res);
        if (res?.tags) {
          res.tags.forEach(item => {
            if (item.type1 === 't_hco') {
              if (item.type2 === '基本属性') {
                this.hcoTagValue.basic.push(item);
              }
              if (item.type2 === '位置属性') {
                this.hcoTagValue.position.push(item);
              }
              if (item.type2 === '经营属性') {
                this.hcoTagValue.business.push(item);
              }
              if (item.type2 === '诊断属性') {
                this.hcoTagValue.diagnosis.push(item);
              }
              if (item.type2 === '腾讯特色') {
                this.hcoTagValue.special.push(item);
              }
            }
            if (item.type1 === 't_hcp') {
              if (item.type2 === '基本属性') {
                this.hcpTagValue.basic.push(item);
              }
              if (item.type2 === '工作属性') {
                this.hcpTagValue.work.push(item);
              }
              if (item.type2 === '执业属性') {
                this.hcpTagValue.practice.push(item);
              }
              if (item.type2 === '学术属性') {
                this.hcpTagValue.academic.push(item);
              }
              if (item.type2 === '腾讯特色') {
                this.hcpTagValue.special.push(item);
              }
            }
          });
        }
        this.sortTagValue();
      });
    },

    sortTagValue() {
      // 1-待上架，2-已上架，3-已下架
      const arr = [2, 1, 3];
      Object.keys(this.hcoTagValue).forEach(ele => {
        this.hcoTagValue[ele].forEach(e => {
          const sortId = arr.indexOf(e.on_self);
          e.sortId = sortId;
        });
        this.hcoTagValue[ele].sort((a, b) => {
          return a.sortId - b.sortId;
        });
      });
      Object.keys(this.hcpTagValue).forEach(ele => {
        this.hcpTagValue[ele].forEach(e => {
          const sortId = arr.indexOf(e.on_self);
          e.sortId = sortId;
        });
        this.hcpTagValue[ele].sort((a, b) => {
          return a.sortId - b.sortId;
        });
      });
    },

    // 打开新建HCO规则标签抽屉
    hanldeOpenTagRule(type = 'HCO') {
      this.createRuleTagTitle = `新建${type}规则标签`;
      this.showCreateRuleTag = true;
    },

    // 关闭HCO规则标签
    closeCreateRuleTag() {
      this.showCreateRuleTag = false;
      this.$refs.createRuleTagForm.resetFields();
    },

    // 验证标签名称
    handlecheckTagName(val) {
      console.log(val);
      if (SPECIAL_CHAR.test(val)) {
        this.$message.error('不能输入特殊字符');
        this.ruleForm.tag_name = '';
      }
    },

    // 验证标签值
    handlecheckTagValue(val) {
      console.log(val);
      // 标签值允许输入小数，这里格外判断
      const arr = ['.'];
      const flag = arr.some(ele => val.indexOf(ele));
      if (flag) return;

      if (SPECIAL_CHAR.test(val)) {
        this.$message.error('不能输入特殊字符');
        // this.ruleForm.tag_value = '';
      }
    },

    // 提交表单
    submitForm() {
      this.$refs.createRuleTagForm.validate(valid => {
        if (valid) {
          console.log(this.ruleForm);
          // 验证标签名称
          if (this.ruleForm.tag_name.trim() === '') {
            this.$message.error('标签值不能是空格字符');
            return;
          }
          let taglist = [];
          if (this.createRuleTagTitle === '新建HCO规则标签') {
            taglist = this.hcoTagValue[this.ruleForm.tag_classification];
          }
          if (this.createRuleTagTitle === '新建HCP规则标签') {
            taglist = this.hcpTagValue[this.ruleForm.tag_classification];
          }
          const flag = taglist.some(ele => this.ruleForm.tag_name === ele.name);
          // flag为true表示存在重复的标签名称
          if (flag) {
            this.$message.error('标签名称重复');
            return;
          }

          // 验证标签值
          if (this.ruleForm.tag_value_type === 'date') {
            this.saveTagRule();
            return;
          }
          if (this.ruleForm.tag_value.trim() === '') {
            this.$message.error('标签值不能是空格字符');
            return;
          }
          const tagVal = this.ruleForm.tag_value.split('\n');
          const setTagVal = [];
          tagVal.forEach(ele => {
            if (ele.trim().length > 0) {
              setTagVal.push(ele.replace(/\s*/g, ''));
            }
          });
          if (new Set(setTagVal).size !== setTagVal.length) {
            this.$message.error('标签值存在重复值');
            return;
          }
          if (setTagVal.length > 500) {
            this.$message.error('标签值最多只能有500个');
            return;
          }

          // 验证标签值是否符合值类型
          const { tag_value_type: tagType } = this.ruleForm;
          if (tagType === 'integer-num') {
            const flag = setTagVal.every(el => Number.isInteger(Number(el)));
            if (!flag) {
              this.$message.error('标签值必须是整数');
              return;
            }
          }
          if (tagType === 'decimal-num') {
            const flag1 = setTagVal.every(el => Number(el));
            if (!flag1) {
              this.$message.error('标签值必须是数字');
              return;
            }
            const flag2 = setTagVal.every(el => String(el).indexOf('.') > -1);
            if (!flag2) {
              this.$message.error('标签值必须是小数');
              return;
            }
          }

          // 验证通过-保存表单
          this.saveTagRule();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // 保存新建规则标签
    saveTagRule() {
      const {
        tag_classification: tagClass,
        tag_attribute: tagAttr,
        tag_value_type: tagType,
      } = this.ruleForm;
      const setTagVal = [];
      if (tagType === 'date') {
        const { year, month, date } = getDate(new Date(this.ruleForm.tag_value));
        setTagVal.push(`${year}-${month}-${date}`);
      } else {
        const tagVal = this.ruleForm.tag_value.split('\n');
        tagVal.forEach(ele => {
          if (ele.trim().length > 0) {
            setTagVal.push(ele.replace(/\s*/g, ''));
          }
        });
      }
      const tagName = this.ruleForm.tag_name.replace(/\s*/g, '');
      const type2Val = this.tagCategory.find(item => item.value === tagClass).label;
      const attrVal = this.tagAttributes.find(item => item.value === tagAttr).label;
      let tagValType = this.tagValueTypes.find(item => item.value === tagType).label;
      if (tagValType === '多值文本') {
        tagValType = '多值';
      }
      if (tagValType === '整数') {
        tagValType = '数值';
      }

      const params = {
        type1: this.createRuleTagTitle === '新建HCP规则标签' ? 't_hcp' : 't_hco',
        type2: type2Val, // 标签2级分类
        name: tagName, // 标签名称
        description: this.ruleForm.tag_description, // 标签说明
        attribute: attrVal, // 标签属性
        value_type: `${tagValType}型`, // 标签值类型
        tag_values: setTagVal, // 标签值列表
        create_way: 1, // 生产方式，1-规则生成，2-算法生成，3-人工录入
        update_way: this.ruleForm.runType, // 更新方式，1-离线更新，2-实时更新
        on_self: 1, // 上架标记，1-待上架，2-已上架，3-已下架
      };
      console.log(params);
      CreateTag(params).then(res => {
        if (res?.id) {
          this.$message.success('创建成功');
          this.closeCreateRuleTag();
          const newTag = { ...params, id: res.id };
          if (this.createRuleTagTitle === '新建HCO规则标签') {
            this.hcoTagValue[tagClass].push(newTag);
          } else {
            this.hcpTagValue[tagClass].push(newTag);
          }
          setTimeout(() => {
            this.gotoTagDetail({ id: res.id });
          }, 500);
        }
      });
    },

    // 跳转tag标签详情
    gotoTagDetail(val) {
      console.log(val);
      if (this.systemEnv === '2' && val.name === '擅长领域') {
        return;
      }
      const query = {
        id: val.id,
      };
      console.log(query);
      const routerData = this.$router.resolve({
        path: '/dashboard/tag-manage-detail',
        query,
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },

    // 切换值类型
    changeValType() {
      this.ruleForm.tag_value = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
